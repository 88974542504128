import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData ,removeStorageData} from "../../../framework/src/Utilities";
import React from "react";
interface ApiRes {
  message:string
}
export interface SearchedCities {
  id: string,
  type: string,
  attributes: {
    city_ascii: string,
    state_id: string,
    state_name: string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleUpdatedSearch?:  (value:string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  cityList: SearchedCities[];
  hoveredIndex: number | null;
  selectedCity: SearchedCities | null;
  isOpen: boolean;
  isOpenLogout: boolean;
  logoutMessage: string
  selectedItem: string;
  activeSection: string;
  deleteAccount: boolean;
  searchText:string;
  anchorEl: null | HTMLElement;
  showCustomUI: boolean;
  isLogout: boolean;
  user_profile_image:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HeaderNavigationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  logoutAPICallId: string = "";
  getuserAccountId:string = "";
  deleteAccountID: string = "";
  searchCitiesAPIcallId: string = "";
  dropdownRef : React.RefObject<HTMLDivElement>
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      cityList: [],
      hoveredIndex: null,
      showCustomUI: false,
      anchorEl: null,
      selectedCity:null,
      searchText:'',
      isOpen: false,
      selectedItem: '',
      activeSection: '',
      isOpenLogout: false,
      logoutMessage: '',
      deleteAccount: false,
      isLogout:false,
      user_profile_image:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.dropdownRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webApiRequestCallId === this.logoutAPICallId) {
        if (webResponseJson.message) {
          this.handleNavigateToLoginAccount();
          this.handleCloseLogoutModal();
          this.setState({ logoutMessage: webResponseJson?.message });
          removeStorageData("LoginToken")
          removeStorageData("RefreshToken")
        }
      }
      if(webApiRequestCallId === this.deleteAccountID){
        this.handleDeleteRes(webResponseJson)
      } else if(webApiRequestCallId === this.searchCitiesAPIcallId){
        this.handleSearchRes(webResponseJson.data)
      }
      else if (webApiRequestCallId === this.getuserAccountId){
        this.setState({user_profile_image:webResponseJson.data.attributes.user_profile_image})

      }
      // Customizable Area End
    }
  }

  // Customizable Area Start
  handleSearch = (event: React.ChangeEvent<{}>, value: string) => {
    this.setState({ searchText: value, showCustomUI: value === "" },
            () => {
              if (this.state.searchText.length <= 5) {
                this.handleSearchCity(); 
              }}
          );
  }
  toggleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleOpenLogoutModal = () => {
    this.setState({ isOpenLogout: true })
  }

  handleCloseLogoutModal = () => {
    this.setState({ isOpenLogout: false })
  }
  handleNavigateToLoginAccount = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  handleMenuItemClick = (item: string) => {
    this.setState({ selectedItem: item }, () => { this.getSidebarPosition() });
    if (item === 'Personal Details') {

      const message = new Message(
        getName(MessageEnum.NavigationMessage));

      message.addData(getName(
        MessageEnum.NavigationTargetMessage), 'CustomisableUserProfiles');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    else if (item === 'Change Password') {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage),
        'Settings2');
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    else if (item === 'Notifications') {
      const message = new Message(getName(
        MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailNotification');
      this.send(message);
    }
    else if (item === 'Delete Account') {
      this.setState({ deleteAccount: true })
    }
    else if(item === 'Terms and Conditions'){
      const message = new Message(getName(
        MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'TermsAndCon');
      this.send(message);
    }
    else if(item === 'Logout'){
      this.handleOpenLogoutModal()
    }
  };
  getSidebarPosition = () => {
    const { selectedItem } = this.state;
    switch (selectedItem) {
      case 'Personal Details':
        return { top: '78px' };
      case 'Change Password':
        return { top: '112px' };
      case 'Notifications':
        return { top: '145px' };
      case 'Delete Account':
        return { top: '172px' };
      default:
        return { top: '78px' };
    }
  };
  handleSectionClick = (section: string) => {
    this.setState({ activeSection: section });
  };
  handleDeleteClose = ()=>{
    this.setState({deleteAccount:false})
  }
  // Logout API
  logoutAPI = async () => {
    this.setState({isLogout:true});
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: await getStorageData("LoginToken"),
      "Refresh-Token" : await getStorageData("RefreshToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.logoutAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutAPIEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPIEndpoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleDeleteAccount = () => {
    const header = {
      token: localStorage.getItem("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${localStorage.getItem("LoginId")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    this.deleteAccountID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCityChange = (event: React.ChangeEvent<{}>, newValue: SearchedCities | null) => {   
    if (newValue) {
      this.setState({
        selectedCity: newValue, searchText: newValue.attributes.city_ascii,
      }, async()=>{
        await setStorageData('searched_cityName', this.state.searchText)
        if(this.props.handleUpdatedSearch){
          this.props.handleUpdatedSearch(this.state.searchText)
        }
      });
    } else {    
      this.setState({ selectedCity: null, searchText: ''}, async()=>{
        await removeStorageData('searched_cityName')
        if(this.props.handleUpdatedSearch){        
          this.props.handleUpdatedSearch("")
        }
      });
    }
  };
  handleSearchRes = (searchResponse:SearchedCities[])=>{
    this.setState({cityList: searchResponse})    
  }     

  handleMouseEnter = (index: number) => {
    this.setState({ hoveredIndex: index });
  };

  handleDeleteRes= (response:ApiRes)=>{
    if(response.message){
      localStorage.setItem("LoginToken", '')
      localStorage.setItem("LoginId", '')
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
      this.send(message);
    }
  } 

  handleMouseLeave = () => {
      this.setState({ hoveredIndex: null });
  };
  handleSearchCity = () => {

    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({token: localStorage.getItem("LoginToken")})
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchCityPath}${this.state.searchText}`
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.searchCitiesAPIcallId = requestMessages.messageId;
    runEngine.sendMessage(requestMessages.id, requestMessages);
  }

  async componentDidMount() {
    document.addEventListener('mousedown',this.handleClickOutside);
    this.getUserData()
  }

  async componentWillUnmount() {
    document.removeEventListener('mousedown',this.handleClickOutside);
  }

  handleClickOutside = (event:MouseEvent) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node)) this.setState({ isOpen: false })
  };
  getUserData = () => {
    const header = {
      token: localStorage.getItem('LoginToken')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getuserAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/fetch_user_personal_details'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
