import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData} from "../../../framework/src/Utilities";
import React from "react";
import { Box, Button } from "@mui/material";
import { tickIcon ,crossIcon} from "./assets";
import {Close} from '@mui/icons-material';

  interface Address {
    city: string;
    state: string;
    address: string;
    country: string;
    zip_code: string;
  };
  interface ApplicantAttributes {
    trucker_id: number;
    user_profile_image_url: string | undefined;
    first_name: string;
    last_name: string;
    phone_number: string;
    insurance: string;
    authority: string;
    driving_license_number: string;
    mc_number: string;
    status: string;
  };
  interface Applicant  {
  id: string;
  type: string;
  attributes: ApplicantAttributes;
  }
  interface ErrRes {
    error?: string;
    message?: string;  
    errors?: string; 
    meta?: {
      message: string;
  }
  }
  interface StatusesResponse {
        label: string;
  };
  interface DriverDetails {
    id: string;
    type: string,
    attributes:{
        load_weight:string,
        total_miles:string,
        applications_count:0,
        origin_address: Address;
        destination_address: Address;
        price: string;
        status: string;
        start_date: string;
        end_date: string;
        commodity: string;
        load_details: string;
        avatarSrc: string;
    },
  }
  interface ChatData {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        sender_id: number;
        twilio_channel_sid: string;
        sender_account: AccountData;
        receiver_id: number;
        unread_count: number;
        receiver_account: AccountData;
      };
    };
    error:string
  }
  interface AccountData {
    data: {
      id: string;
      type: string;
      attributes: {
        email: string;
        activated: boolean;
        country_code: string | null;
        last_name: string;
        first_name: string;
        phone_number: string | null;
        full_phone_number: string;
        created_at: string;
        type: string;
        device_id: string | null;
        updated_at: string;
        profile_data: ProfileData;
        unique_auth_id: string;
        user_profile_image: string;
      };
    };
  }

  interface ProfileData {
    truck_type?: string[];
    mc_number?: string;
    insurance?: string;
    authority?: string;
    driving_license_number?: string;
  }
  interface IPerticularMessages {
    "id": string,
    "twilio_channel_sid": string,
    "receiver_id": number,
    "sender_id": number,
    "unread_count": boolean,
    "other_user_is_online": boolean,
    "user_profile_image":string,
    "messages": IMessageList[],
    "first_name":string,
    }
    export interface IMessageList {
      "id": string,
      "attributes": {
        "message": string,
        "account_id": number,
        "is_mark_read": boolean,
        "created_at": string,
        "attachments": null | {
          url:string,
          id:string,
        }[]
      }
    }
    interface IUserList {
      "id": string,
      "type": string,
      "attributes": {
        "sender_id": number,
        "twilio_channel_sid": string,
        "latest_message_id": number,
        "receiver_id": number,
        "latest_message_created_at": string,
        "latest_message_content": string,
        "sender_account": {
          "data": {
            "attributes": {
              "activated": boolean,
              "last_name": string,
              "first_name": string,
              "user_profile_image": string 
            }
          }
        },
        "receiver_account": {
          "data": {
            "attributes": {
              "activated": boolean,
              "last_name": string,
              "first_name": string,
              "user_profile_image": string 
            }
          }
        },
        "unread_count": number,
        "other_user_is_online":boolean,
      }
    }


    export interface IMessageList {
      "id": string,
      "attributes": {
        "account_id": number,
        "message": string,
        "is_mark_read": boolean,
        "created_at": string,
        "attachments": null | {
          url:string,
          id:string,
        }[]
      }
    }
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    tabValue: number;
    loadsDataList: DriverDetails[];
    selectedCardId: string;
    status: string;
    applicantsList: Applicant[];
    expandedApplicantId: string;
    selectedStatuses: string[];
    statuses: string[];
    allStatus: StatusesResponse[];
    menuAnchorEl: any;
    selectedMenuId: string | null;
    isOpenApprove: boolean;
    applicantName: string;
    applicationId: string;
    errorMessage: string;
    successMessage: string;
    isOpenReject: boolean;
    isChatShow:boolean;
    deleteLoadId: string;
    isOpenDelete: boolean;
    particularChat :IPerticularMessages;
    userList:IUserList[];
    truckerId:number;
    inputMessage:string;
    fileUploaded: Blob;
    userD:boolean;
    isLoading :boolean;
    attachmentError:string
    // Customizable Area End
}
interface SS { }

export default class MyLoadsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    showAllMyLoadsAPICallId:string = '';
    applicationsAPICallId:string = ''; 
    showAllStatusAPICallId:string = '';
    showFilterLoadsAPICallId:string = '';
    getApprovedAPICallId:string = '';
    getRejectedAPICallId:string = '';
    getDeleteAPICallId:string = '';
    createChatid:string = "";
    getPerticularUsersChatAPIcallID:string = "";
    allChatListId:string = "";
    sendMessageAPICallId:string = "";
    loginId:string = "";
    getuserAccountId:string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),            
        ];

        this.state = {
            tabValue: 0,
            isLoading :true,
            selectedCardId: '0',
            status: '',
            loadsDataList: [],
            applicantsList: [],
            expandedApplicantId: '0',
            selectedStatuses: [],
            statuses: [],
            allStatus: [],
            menuAnchorEl: null, 
            selectedMenuId: null,
            isOpenApprove: false,
            applicantName: '',
            applicationId: '',
            errorMessage: '',
            successMessage:'',
            isOpenReject: false,
            isChatShow: false,
            deleteLoadId: '',
            isOpenDelete: false,
            particularChat:{} as IPerticularMessages,
            userList:[],
            truckerId:0,
            inputMessage:"",
            fileUploaded: {} as Blob,
            userD:false,
            attachmentError:""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        this.showAllMyLoads()
        this.getAllStatus()
        this.loginId = await getStorageData("LoginId");
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start  
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors) {
              switch(apiRequestCallId){
                case this.showAllMyLoadsAPICallId:
                this.handleAllMyLoadData(responseJson);
                break;
                case this.applicationsAPICallId:
                  this.handleApplicationsData(responseJson);
                  break;
                case this.showAllStatusAPICallId:
                  this.handleAllStatusData(responseJson);
                  break;
                case this.showFilterLoadsAPICallId:
                  this.handleAllMyLoadData(responseJson);
                  break;
                case this.getApprovedAPICallId:
                  this.handleSuccessApprovedData(responseJson);
                  break;
                case this.getRejectedAPICallId:
                  this.handleSuccessApprovedData(responseJson);
                  break;
                case this.getDeleteAPICallId:
                  this.handleDeleteSuccessData(responseJson);
                  break;
                case this.createChatid:
                  this.handleCreateChatRes(responseJson);
                  break;
                case this.getPerticularUsersChatAPIcallID:
                  const senderInfo = +this.loginId === responseJson.data.attributes.sender_id
                  ? responseJson.data.attributes.receiver_account.data.attributes
                  : responseJson.data.attributes.sender_account.data.attributes;
                  const responseData = {
                    "id": responseJson.data.id,
                    "sender_id": responseJson.data.attributes.sender_id,
                    "twilio_channel_sid": responseJson.data.attributes.twilio_channel_sid,
                    "other_user_is_online": responseJson.data.attributes.other_user_is_online,
                    "receiver_id": responseJson.data.attributes.receiver_id,
                    "messages": responseJson.data.attributes.messages.data,
                    "unread_count": responseJson.data.attributes.unread_count,
                    "first_name": senderInfo.first_name,
                    "user_profile_image": senderInfo.user_profile_image,
                  }
                  this.setState({particularChat: responseData})
                  break;
                case this.allChatListId:
                  this.setState({ userList: responseJson.data }) 
                  const {  truckerId } = this.state;
                
                  let selectedUser = this.state.userList.find(user => user?.attributes?.receiver_id == truckerId);
                  if(selectedUser== undefined){
                    selectedUser = this.state.userList.find(user => user?.attributes?.sender_id === truckerId);
                  }
                  this.getPerticularUsersChat(selectedUser?.id)
                  break;
                case this.sendMessageAPICallId:
                  this.setState({fileUploaded : {} as Blob})
                  this.allChatsList()
                  break;
                case this.getuserAccountId:
                  this.setState({userD:true})
                  break

              }
            }
        }     
        // Customizable Area End
    }
    // Customizable Area Start
    handleAllStatusData = (responseJson:{ statuses: StatusesResponse[] })=>{   
        const statusLabels = responseJson.statuses.map(status => status.label);
        this.setState({statuses:statusLabels})
    }
    handleAllMyLoadData=(responseJson:{ data: DriverDetails[] })=>{   
        const reverseList = [...responseJson.data].reverse();
        this.setState({loadsDataList:reverseList, isLoading: false},()=>{            
            responseJson.data.length && this.setState({selectedCardId:this.state?.loadsDataList[0].id})
            responseJson.data.length && this.getApplicationsData(this.state?.loadsDataList[0].id)
        })
    }
    getButtons = (status: string, first_name: string, last_name: string, applicantId: string) => {
        const webStyles= {
            cancelButton: {
                height: '56px', width: '170px', color: '#B57730',fontFamily: 'Inter',fontSize: '16px',fontWeight: 700,backgroundColor: '#FDF1E2',cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#FDF1E2',
                }
              },
              LogoutButton: {
                height: '56px', width: '170px',color: '#FFFFFF',fontFamily: 'Inter',fontSize: '16px',fontWeight: 700,cursor: 'pointer',background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
                '&:hover': {
                  background: 'linear-gradient(99.09deg, #915F26 2.64%, #F19F40 100%)',
                }
              },
              appliedButton: {
                height: '56px', width: '170px',color: '#64748B',fontFamily: 'Inter',fontSize: '16px',fontWeight: 700,cursor: 'pointer',backgroundColor: '#F1F5F9',
                '&:hover': {
                    backgroundColor: '#F1F5F9',
                  }
              }
        }
        if(status === 'approved') {
            return <Box style={{display: 'flex', justifyContent:'center', margin: '0px 50px 40px 50px'}}>
            <Button variant="contained" sx={webStyles.appliedButton}>APPROVED</Button>
            </Box>
        } else if(status === 'rejected') {
            return <Box style={{display: 'flex', justifyContent: 'center', margin: '0px 50px 40px 50px'}}>
                <Button variant="contained" sx={webStyles.appliedButton}>REJECTED</Button>
                </Box>
        } else {
            return <Box style={{display: 'flex', justifyContent: 'space-between', margin: '0px 50px 40px 50px', gap:'10px' }}>
                <Button variant="contained" data-testId='rejectBtn' sx={webStyles.cancelButton} onClick={()=>this.handleOpenRejectModal(applicantId, first_name, last_name)}>REJECT</Button>
                <Button variant="contained" data-testId='approveBtn' sx={webStyles.LogoutButton} onClick={()=>this.handleOpenApproveModal(applicantId, first_name, last_name)}>APPROVE</Button>
            </Box>
            
        }
    }
    handleApplicationsData=(responseJson:{ data: Applicant[] })=>{    
        this.setState({applicantsList:responseJson.data })
    }
    handleSuccessApprovedData = (responseJson: ErrRes )=>{ 
        if(responseJson.error){
            this.setState({errorMessage:responseJson.error })
        }
        if(responseJson.message){
            this.setState({successMessage:responseJson.message })
        }
        this.handleCloseApproveModal();
        this.handleCloseRejectModal();
        this.setState({applicantName: "", applicationId:''})
    }
    handleDeleteSuccessData = (responseJson: ErrRes )=>{ 
        if(responseJson.errors){
            this.setState({errorMessage:responseJson.errors })
        }
        if(responseJson.meta){
            this.setState({successMessage:responseJson.meta.message })
        }
        this.handleCloseDeleteModal();
    }
    showAllMyLoads = async () => {      
            this.showAllMyLoadsAPICallId = await this.apiCall({
                method: configJSON.getApiMethodType,
                endPoint: configJSON.myLoadsPath,
                token: await getStorageData("LoginToken")
              });
    };
    apiCall = async (data: any) => {
        const { method, endPoint, body, token, isJsonStringify } =
          data;
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
          "Content-Type": configJSON.apiContentType,
          token: token,
        };
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        body &&
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            isJsonStringify ? JSON.stringify(body) : body
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
    getApplicationsData = async (selectedId:string) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: await getStorageData("LoginToken")
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.applicationsAPICallId = requestMsg.messageId;

        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.applicationsPath}?load_id=${selectedId}`);
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    };
    getAllStatus = async() => {
        this.showAllStatusAPICallId = await this.apiCall({
            method: configJSON.getApiMethodType,
            endPoint: configJSON.getStatusesPath,
            token: await getStorageData("LoginToken")
        });
    }
    getFilterLoads = async() => {
        const newPath = this.state.selectedStatuses.map(status => `statuses[]=${status}`).join('&');         
        this.showFilterLoadsAPICallId = await this.apiCall({
            method: configJSON.getApiMethodType,
            endPoint: `${configJSON.myLoadsPath}?${newPath}`,
            token: await getStorageData("LoginToken")
        });
    }
    handleRejected = async() => {
      this.getRejectedAPICallId = await this.apiCall({
          method: configJSON.patchApiMethodType,
          endPoint: `${configJSON.applicationsPath}/${this.state.applicationId}/reject`,
          token: await getStorageData("LoginToken")
      });       
    };
    handleDeleted = async() => {
      this.getDeleteAPICallId = await this.apiCall({
          method: configJSON.deleteApiMethodType,
          endPoint: `${configJSON.getDeletePath}/${this.state.deleteLoadId}`,
          token: await getStorageData("LoginToken")
      });       
    };
    getAllStatusSelected = () => {
      if(this.state.statuses.length > 0){
        return this.state.selectedStatuses.length === this.state.statuses.length;
      }       
    }
    formateDate = (date: string) => {
      const dateNew = new Date(date)
        return dateNew.toLocaleDateString('en-US', {
          weekday: 'short', 
          month: 'short',  
          day: 'numeric'   
        });
    }
    handleApproved = async() => {
        this.setState({errorMessage:'', successMessage:'' })
        this.getApprovedAPICallId = await this.apiCall({
            method: configJSON.patchApiMethodType,
            endPoint: `${configJSON.applicationsPath}/${this.state.applicationId}/approve`,
            token: await getStorageData("LoginToken")
        });       
    };
    handleOpenApproveModal = (applicationId: string, firstName: string, lastName: string) => {
        this.setState({ isOpenApprove: true, applicantName: firstName +" "+ lastName, applicationId })       
      }
    handleCloseRejectModal = () => {
      this.setState({ isOpenReject: false })
    }
    handleOpenRejectModal = (applicationId: string, firstName: string, lastName: string) => {
      this.setState({ isOpenReject: true, applicantName: firstName +" "+ lastName, applicationId })       
    }
    handleCloseApproveModal = () => {
        this.setState({ isOpenApprove: false })
    }    
    
    handleCloseDeleteModal = () => {
        this.showAllMyLoads()
        this.setState({ isOpenDelete: false, deleteLoadId: ''})
    }
    openDeleteModel = (loadId: string) => {
        this.setState({ isOpenDelete: true, deleteLoadId: loadId, });
    }
    handleCardClick = (cardId: string) => {
        this.setState({ isChatShow: false, selectedCardId: cardId, expandedApplicantId :'0'},()=>{
            this.getApplicationsData(cardId)
        });       
    };
    isCardSelected = (cardId: string): boolean => {
        return cardId === this.state.selectedCardId;
    };
    handleApplicant = (applicantId: string) => {
        this.setState({
          expandedApplicantId: applicantId,
        });
    };
    handleApplicantDetail = () => {
        this.setState({
          expandedApplicantId: '0',
        });
    };
    handleChange = (event: any) => {
        const value = event.target.value;
        const { selectedStatuses, statuses } = this.state;
        if (value.includes("All")) {
            this.setState({
                selectedStatuses: selectedStatuses.length === statuses.length ? [] : statuses
            }, ()=>{ this.getFilterLoads()});
            return;
        }
        this.setState({
            selectedStatuses: value
        }, ()=>{ this.getFilterLoads()});
      };
      handleButtonClick = (event: any, cardId: string) => {
        this.setState({ menuAnchorEl: event.currentTarget as HTMLElement, selectedMenuId: cardId, });
      };
    
      handleEditMenuClose = () => {
        this.setState({ menuAnchorEl: null });
      };
      handleNavigation = async(route: string, loadId: string) => {
        await setStorageData('editId', Number(loadId));
        if (loadId) {
          this.props.navigation.navigate('PostCreationEdit', { id: loadId });
        }else {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
        }
      };
      getBorder = (cardItemId: string) =>{
        return this.isCardSelected(cardItemId) ? '2px solid #7b6344' : ''
      }
      getBoxShadow = (cardItemId: string) =>{
        return this.isCardSelected(cardItemId) ? '0px 4px 4px 0px #a87f51' : '0px 4px 4px 0px #00000040'
      }
      showSuccMessage = () => {
        const {errorMessage, successMessage} = this.state;
          const webStyles = {
              backdrop: {
                  top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'end', zIndex: 999,
              },
              toast: {
                  backgroundColor: '#fff', padding: '16px 24px', borderRadius: '8px', display: 'flex', alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', marginBottom: '70px'
              },
              icon: {
                  fontSize: '24px', marginRight: '16px',
              },
              message: {
                  fontSize: '20px', fontWeight: 400, color: '#0F172A', fontFamily: 'Inter'
              },
              closeButton: {
                  background: 'none', border: 'none', color: '#999', cursor: 'pointer', left: '12px'
              },
              crossImage: { backgroundColor: '#F87171', borderRadius: '50%', padding: '3px', marginRight: '10px' },
          }
          
        return ( <>
            {this.state.successMessage && (
                <div style={{position: 'fixed',...webStyles.backdrop}}>
                  <div style={{ position: 'relative',...webStyles.toast}}>
                    <div style={webStyles.icon}>
                      <span role="img" aria-label="check"><img src={tickIcon} alt="" width={'30px'}/></span>
                    </div>
                    <div style={webStyles.message}>{successMessage}</div>
                    <button style={{ position: 'relative',...webStyles.closeButton}} onClick={this.closeToast}>
                      <img src={crossIcon} alt="closeToast" />
                    </button>
                  </div>                  
                </div>                
              )}
              {this.state.errorMessage && (
                <div style={{position: 'fixed',...webStyles.backdrop}}>
                  <div style={{ position: 'relative',...webStyles.toast}}>
                    <div style={webStyles.crossImage}> 
                        <Close style={{color:'#FFF', fontSize:'24px'}}/>
                    </div>
                    <div style={webStyles.message}>{errorMessage}</div>
                    <button style={{ position: 'relative',...webStyles.closeButton}} onClick={this.closeToast}>
                      <img src={crossIcon} alt="" />
                    </button>                    
                  </div>                  
                </div>                
              )}
              </>        
          )
      }
      closeToast=()=>{
        this.setState({successMessage:'', errorMessage:''})
      }
      closeChat = ()=>{
        this.setState({isChatShow:false})
      }
      chatStart = (firstName: string, lastName: string,validId:number)=>{        
        this.setState({isChatShow:true, applicantName: firstName +" "+ lastName,})
        this.setState({truckerId :validId})
        this.startChat(validId)
      }
      formatLoadWeight = (loadWeight: string) => {
        const numericWeight = parseFloat(loadWeight);
        if (isNaN(numericWeight)) return '';

        const weight = numericWeight % 1 === 0
          ? numericWeight.toFixed(0)
          : numericWeight.toFixed(1);

        return `${weight} ${numericWeight === 1 ? 'Ton' : 'Tons'}`;
      } 
      formatLoadMiles = (miles: string) => {
        const numericMiles = parseFloat(miles);
        if (isNaN(numericMiles)) return '';

        const weight = numericMiles % 1 === 0
          ? numericMiles.toFixed(0)
          : numericMiles.toFixed(1);

        return `${weight} ${numericMiles === 1 ? 'Mile' : 'Miles'}`;
      }
      getOverflow = () => {   
        return this.state.expandedApplicantId === '0' ? 'auto' : 'hidden';
      }
      getHeight = () => {   
        return this.state.expandedApplicantId === '0' ? '450px' : 'max-content';
      }

      startChat = async(validId :number) =>{
        const header = {
          "Content-Type": configJSON.apiContentType,
          token: await getStorageData("LoginToken")
        };
        const httpBody = {
          "data": {
            "receiver_id":validId
        }
        }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createChatid = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_chat/chats`);
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      handleCreateChatRes = (response:ChatData)=>{
        if(response?.data?.id){
          this.getPerticularUsersChat(response?.data?.id)
        }
        else if(response.error){
          this.allChatsList()
        }
      }

      getPerticularUsersChat = async(id:string | undefined) => {
        
        const header = {
          "Content-Type": configJSON.advancedsearchApiContentType,
          token: await getStorageData("LoginToken")
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPerticularUsersChatAPIcallID = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getPerticularUsersChatEndPoint}${id}`);
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethodType
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

      allChatsList = async() => {
        const header = {
          "Content-Type": configJSON.advancedsearchApiContentType,
          token: await getStorageData("LoginToken")
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        
        this.allChatListId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getPerticularUsersChatEndPoint}`);
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      handleOnChange = (innerHtml: string, textContent: string, innerText: string) => {
        this.setState({
          inputMessage:innerText,
          attachmentError:""
        })
      }
      onSendMessage = async() =>{
        const header = {
          "Content-Type": configJSON.advancedsearchApiContentType,
          token: await getStorageData("LoginToken")
        };
    
        const formData = new FormData();
    
        formData.append("twilio_channel_sid", this.state.particularChat.twilio_channel_sid);
        formData.append("data[message]", this.state.inputMessage);
        this.state.fileUploaded.type && formData.append("data[attachments][]", this.state.fileUploaded as Blob)
        
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sendMessageAPICallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.postSendMessageApiEndPoint}`);
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethodType
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
    
        
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
      
      handleFileUpload = (file:File) => this.setState({ fileUploaded : file});
      handleOnClear = () => this.setState({ fileUploaded : {} as Blob});
      handleOnAttachClick = () => {
        const input = document.createElement('input');
        input.setAttribute("data-test-id","file_input")
        input.accept = 'image/*,application/pdf'; 
        input.type = 'file';
        input.onchange = (event) => {
          const target = event.target as HTMLInputElement; 
          const file = target.files?.[0];
          if (file) {
            this.handleFileUpload(file);
          }
        };
        input.click();
      }

      UsersData = () => {
        const header = {
          token: localStorage.getItem('LoginToken')
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        
        this.getuserAccountId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'bx_block_profile/profiles/fetch_user_personal_details'
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
    // Customizable Area End
}