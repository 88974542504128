import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IInputError {
  first_name:string,
  lastName:string,
  email: string;
  phoneNumber: string;
  truckType:string;
  insurance:string;
  licenseNumber:string;
  mcNumber:string;
  authority:string;
}
import { SelectChangeEvent } from '@mui/material/Select';
export interface IAttributeValues {
  name: string;
  value: string;
}
import React from 'react';
import { Box, } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}
interface ProfileAttributes {
  id: number;
  first_name: string;
  last_name: string;
  phone_number: string | null;
  email: string;
  truck_type: string[];
  insurance: string | null;
  mc_number: string | null;
  driving_license_number: string | null;
  authority: string | null;
  user_profile_image: string | null;
}
interface ProfileData {
  id: string;
  type: string;
  attributes: ProfileAttributes;
}
interface ProfileMeta {
  message: string;
}
interface ProfileResponse {
  data: ProfileData;
  meta: ProfileMeta;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: any ;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  avatar:any,
  fileBackend:any,
  anchorEl: any,
  fileInputRef:any;
  [key: string]: any;
  showModal:boolean,
  showToast:boolean,
  toastRef:any,
  trucker :boolean,
  authority:string | null,
  mcNumber:string | null,
  licenseNumber:string | null,
  insurance:string | null,
  truckType:string[];
  inputErrors: IInputError
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  userAccountId:string = "";
  editUserDetailsId:string = "";
  names:any =  [
    'VAN', 'REEFER', 'FLATBED', 'STEPDECK',' HOTSHOT', 'BOX TRUCK',' SPRINTER','RGN/LOWBOY'
  ];
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      avatar:'',
      fileBackend:null,
      anchorEl: null,
      fileInputRef : React.createRef(),
      showModal:false,
      showToast:false,
      toastRef : React.createRef(),
      trucker:localStorage.getItem("LoggedInUserType") === 'trucker'?true:false,
      authority:'',
      mcNumber:'',
      licenseNumber:'',
      insurance:"",
      truckType:[],
      inputErrors: {} as IInputError,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.UserData()
    document.addEventListener('mousedown', this.handleClickOutside);
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.editUserDetailsId){
      this.handleUpdateRes(responseJson)
    }
    if(apiRequestCallId === this.userAccountId){
      this.handleGetData(responseJson)
    }

    // Customizable Area End
  }

  // Customizable Area Start

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate() {
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleGetData= (response:ProfileResponse)=> {
    const {data} = response;
    const {attributes}= data;
        this.setState({first_name:attributes?.first_name,
          lastName:attributes.last_name,
          email:attributes.email,
          phoneNumber:attributes?.phone_number,
          avatar: attributes.user_profile_image ,
          authority:attributes.authority,
          insurance:attributes.insurance,
          mcNumber:attributes.mc_number,
          licenseNumber:attributes.driving_license_number,
          truckType:attributes.truck_type
        })
  }

  editUserProfile = ()=>{
    const header = {
      token: localStorage.getItem('LoginToken')
    };
    const formData = new FormData();
    formData.append('first_name', this.state.first_name.trim());
    formData.append('last_name', this.state.lastName.trim());
    formData.append('email', this.state.email.trim());
    formData.append('phone_number', this.state.phoneNumber.trim());
    if(this.state.fileBackend){
      formData.append('user_profile_image',this.state.fileBackend)
    }
    formData.append('is_remove_image',(this.state.fileBackend || this.state.avatar)?'false':'true')
    if(this.state.trucker){
      formData.append('truck_type',this.state.truckType.join(','))
      formData.append('insurance',this.state.insurance as string)
      formData.append('mc_number',this.state.mcNumber as string)
      formData.append('driving_license_number',this.state.licenseNumber as string)
      formData.append('authority',this.state.authority as string)
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.editUserDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/update_user_details'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPutMethod);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUpdateRes(response:any){
    if(response.data){  
      this.setState({showToast:true})
    }
  }

  validationForName = (name:string, updateError: IInputError | any) => {

    if (!this.state[name]) {
      updateError[name] = `${name.includes("first")? "First" :"Last"} Name is required.`
    } else if (this.state[name].length < 2 || this.state[name].length > 50) {
      updateError[name] = `${name.includes("first")? "First" :"Last"} Name must be between 2 and 50 characters long.`
    } else if (!(/^[a-zA-Z\s]*$/.test(this.state[name]))) {
      updateError[name] = "Special characters and numbers are not allowed."
    }
  }

  handleValidate = () => {
    let updateError = {...this.state.inputErrors} as IInputError
    const { email, phoneNumber} = this.state;

    this.validationForName("first_name", updateError);
    this.validationForName("lastName", updateError);

    if(!(/^(?!.*\.\..*)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))){
      updateError.email = "Please enter a valid email address."
    }

    if(!(/^\+1\d{10}$/.test(phoneNumber))){
      updateError.phoneNumber = "The phone number must be exactly 10 digits after '+1."
    }

    if(this.state.trucker){
      this.truckerInputValidation(updateError);
    }
  
    this.setState({inputErrors:updateError});

    return Object.values(updateError).every(value => value === ""); 
  }

  truckerInputValidation = (updateError: IInputError) => {
    if(this.state.truckType.length === 0){
      updateError.truckType = "Please select at least one truck type"
    }
    this.licenseNumberValidation(updateError)
    if(!this.state.authority){
      updateError.authority = "Authority filed is required "
    } else if(this.state.authority.length > 50){
      updateError.authority =  "Max 50 characters allowed for the authority name."
    }
    if(!this.state.insurance){      
      updateError.insurance = "Insurance filed is required "
    }else if(!(/^[A-Za-z\s]+$/.test(this.state.insurance))){
      updateError.insurance = "Insurance name must not contain numbers or special characters." 
    } else if(this.state.insurance.length > 50){
      updateError.insurance = "Max 50 characters allowed for the insurance name." 
    }
  }

  licenseNumberValidation = (updateError: IInputError) => {    
    if(!this.state.licenseNumber){
      updateError.licenseNumber = "Driver License is required "
    } else if (this.state.licenseNumber.length > 15) {
      updateError.licenseNumber = "Max 15 characters allowed for the driver’s license.";
    }
    if(!this.state.mcNumber){
      updateError.mcNumber = "MC Number is required "
    } else if (this.state.mcNumber.length > 10) {
      updateError.mcNumber = "Max 10 characters allowed for the MC number.";
    }  
    
  }
  saveAndNext=()=>{
    if(this.handleValidate()) { 
      this.editUserProfile();
    }
  }
  handleOpenMenu = (event:any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const {name,value}= event.target;

    if (name === "phoneNumber" && !(/^\+?\d*$/.test(value))) {
       return; 
    }

    this.setState((prevState) => ({
      [name]: value,
      inputErrors: { ...prevState.inputErrors, [`${name}`]: '' },
    }))
  };

  handleImageChange = (event:any) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({ fileBackend:file, avatar: URL.createObjectURL(file),anchorEl:null });
      this.handleCloseMenu();
    }
  };
  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleRemovePicture = () => {
    this.setState({ avatar: null,fileBackend:'' });
    this.handleCloseModal()
  };
  handleUploadPicture = () => {
    if (this.state.fileInputRef.current) {
      this.state.fileInputRef.current.click(); 
    }
  };

  EditProfileCircle = ()=> {
    return (
      <Box
        onClick={this.handleOpenMenu}
        sx={{
          width: 164,
          height: 164,
          borderRadius: '50%',
          backgroundColor: '#000000BF',
          color: '#FFFFFF',
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: 700,
          fontFamily: 'Inter',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',gap:'5px'}}>
                             <CameraAltOutlinedIcon sx={{ fontSize: 25 }} />
                            Edit Profile
                             </div>
      </Box>
    );
  };
  handleCloseModal = () => {
    this.setState({ showModal: false ,anchorEl: null});
  };

  handleopenNewmodal = () => {
    this.setState({ showModal: true });
  };
  handleClickOutside = () => {
      this.cloasToast()
  };
  handleChange2 = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    this.setState({
      truckType: typeof value === 'string' ? value.split(',') : value,
    });
  };

  UserData = () => {
    const header = {
      token: localStorage.getItem('LoginToken')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.userAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/profiles/fetch_user_personal_details'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  cloasToast =  ()=>{
    this.setState({showToast:false})
}

handleChangeAttributeValue = (value: string, index: number) => {
  const temporaryValue = this.state.attributes;
  temporaryValue[index].value = value;
  this.setState({ attributes: temporaryValue });
};

fetchProfileData = async () => {
  const header = {
    "Content-Type": "application/json",
    token: configJSON.temporaryToken
  };

  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

  this.getProfileAccountID = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.baseURLGetProfileAccount
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

  // Customizable Area End
}
